@import "../../theme/core.less";

.error-banner {
    display: flex;
    justify-content: space-between;
    background-color: white;
    margin-top: 40px;
    border-radius: 8px;

    &__info {
        padding: 32px;
    }

    &__message {
        .h3();
        color: @text-secondary-color;
        margin-top: 24px;
    }
}


@import "../../theme/core.less";

.ant-modal {
    &.presentation-viewer-modal {

        .current-page {
            .main-text();
            color: @text-color-secondary;
            margin-bottom: 8px;
            text-align: center;
            margin-top: 24px;
        }

        .slider-nav-arrow {
            cursor: pointer;
        }

        .ant-modal-title {
            .h3();
            color: @text-primary-color;
        }

        .react-pdf__Page__canvas, .react-pdf__Document {
            max-width: 1255px;
            max-height: 705px;
            width: 100% !important;
            height: 100% !important;
        }

        .react-pdf__Page__canvas {
            border: 1px solid @border-color;
            border-radius: 10px;
        }

        .react-pdf__Document {
            margin: 0 12px;
        }

        .react-pdf__Page__textContent {
            width: 100% !important;
            height: 100% !important;
        }

        .viewer-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            min-height: 705px;

            @media screen and (max-width: 1366px) {
                min-height: auto;
            }
        }

        .loading-overlay {
            z-index: 10;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(255,255,255,0.4);
        }
    }
}

@import "../../theme/core.less";

.project-target {
    position: relative;

    .page-banner {
        position: absolute;
        right: 0px;
        bottom: 0px;
        z-index: 3;
    }

    .grid-player-wrapper {
        justify-content: center;
        display: flex;
        align-items: center;

        .player {
            max-height: 320px;
            max-width: 560px;
            width: 100%;
            height: 100%;
        }
    }

    .grid {
        display: grid;
        column-gap: 16px;
        grid-template-columns: 3fr 2fr;

        .title {
            .h2();
            font-weight: bold;
            color: @text-primary-color;
        }

        .description {
            .buttons();
            margin-bottom: 32px;
            color: @text-primary-color;
        }
    }

    .stats-list-header {
        margin-top: 24px;
    }

    .stats-list {
        display: flex;
        margin-bottom: 32px;

        .list-item {
            display: flex;
            flex-direction: column;

            &:not(:last-child) {
                margin-right: 35px;
            }

            .item-title {
                .h1();
                color: @brand-second;
                display: block;
            }

            .item-description {
                .buttons();
                color: @text-primary-color;
                margin-bottom: 4px;

                &.accent {
                    color: @brand-second;
                }
            }
        }
    }

    .action-list {
        display: flex;
        align-items: center;

        .ooc-button-v2 {
            color: @primary-color;
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;

                path {
                    fill: @primary-color;
                }
            }

            &:first-child {
                margin-right: 40px;
                color: white;

                svg {
                    path {
                        fill: white;
                    }
                }
            }

            &.ant-btn-ghost {
                max-width: fit-content !important;
            }
        }
    }
}

@media screen and (max-width: 756px) {
    .project-target {
        padding: 20px;
        
        .grid {
            grid-template-columns: 1fr;
        }

        .grid-content {
            .title {
                .mobile-h3();
            }

            .description {
                margin-top: 16px;
                margin-bottom: 20px;
            }
        }

        .yt-lite {
            .lty-playbtn {
                border-radius: 50%;
                border: 1px solid white;
                border-width: 5px;
                background-color: transparent;
                width: 56px;
                height: 56px;
                opacity: initial;

                &:before {
                    left: 26px !important;
                }
            }
        }

        .lite-player-banner__wrapper {
            display: block;
            text-align: right;
            margin-top: -19px;
            margin-right: -16px;
            z-index: 1;
            position: relative;

            img.lite-player-banner {
                height: 72px;
            }

            &::after {
                content: "";
                display: inline-block;
                width: 1px;
                height: 48px;
                background-color: @primary-color;
                margin-bottom: -15px;
            }
        }

        .grid-player-wrapper {
            .player {
                max-height: 320px;
                max-width: 560px;
                width: 100%;
                height: 100%;
            }
        }

        .stats-list-header {
            margin-top: initial;
        }

        .stats-list {
            margin-top: 16px;
            flex-direction: column;

            .list-item {
                &:not(:last-child) {
                    margin-bottom: 24px;
                    margin-right: 0;
                }

                span.item-title {
                    .h2();
                }

                .item-description:not(.accent) {
                    .mobile-main-text();
                }

                .item-description {
                    margin-bottom: initial;
                }
            }
        }

        .action-list {
            flex-direction: column;
            gap: 20px;

            .ooc-button-v2 { 
                
                &.ant-btn-primary {
                    margin: 0;
                }

                &.ant-btn-link {
                    gap: 8px;
                    flex-direction: row-reverse;
                    height: 20px;
                    min-width: 20px;

                    svg {
                        min-width: 20px;
                        margin-right: initial;
                    }
                }
            }
        } 
    }
}


@import "../../theme/core.less";

.ant-modal.modal-consortium-apply {
    max-width: 904px;

    .ant-modal-body {
        .main-text();
    }

    .ant-modal-footer {
        & > .ooc-button-v2 {
            padding: 12px 0;
        }
    }
}

.modal-consortium-apply {
    max-width: 904px;

    &__header {
        margin-top: 16px;
        margin-bottom: 24px;
    }

    &__body {
        padding-right: 40px;

        .bulletpoint {
            margin-bottom: 8px;
            color: #3B4168;

            &__wrapper {
                display: flex;
                flex-direction: column;
            }

            &__title {
                .h5();
                font-weight: 600;
            }

            &__content {
                .main-text();
                color: #909EBB;
            }
        }

        ul { list-style-type: none; padding-left: 0; }

        li {
            display: flex;
            // ordered list with 2 digits
            counter-increment: count;

            &:before {
                content: counter(count)'.';
                // margin-right: 16px;
                margin-right: 56px;
                .h1();
                color: #F0F2FF;
            }

            &:nth-child(-n+9):before {
                content: '0'counter(count);
            }
        }
    }
}


@import "../../theme/core.less";

.priority-industries {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .description {
        max-width: 50%;
    }

    .industries-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 4;
        row-gap: 16px;

        .h-item {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: @text-primary-color;

            svg {
                margin-right: 12px;

                path {
                    fill: @text-primary-color;
                }
            }
        }
    }
}

@media screen and (max-width: 756px) {
    .priority-industries {
        .title {
            .mobile-h3();
        }

        .description {
            max-width: 100%;
            .mobile-main-text();
        }
        
        .industries-list {
            grid-template-columns: 1fr;
        }
    }
}


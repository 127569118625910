@import '../../theme/core.less';

.page-mobile-corner {
    height: 40px;
    width: 40px;
    position: absolute;
    bottom: -40px;
    left: 4px;
    background-color: #fff;

    &__content {
        height: 40px;
        width: 40px;
        border-top-left-radius: 24px;
        background-color: #F5F6F8;
    }
}


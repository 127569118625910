@import '../../theme/core.less';

.ant-input.ooc-input {
    height: @inp-v2-height;
    padding: @inp-v2-padding;
    border: @inp-v2-border;
    border-radius: @inp-v2-border-radius;

    font-family: @inp-font-family;
    font-style: @inp-font-style;
    font-size: @inp-v2-font-size;
    line-height: @inp-v2-line-height;
}

.ooc-input {
    &&__form-input {
        .ant-form-item-label label {
            color: @label-color;
        }

        .ant-input {
            border: 1px solid @border-color;
            border-radius: 4px;
        }
    }

    .label {
        color: @label-color;
    }
}

.ooc-input__textarea {
    min-height: 200px !important;

    & > textarea {
        padding: 8px 16px;
    }

    & > textarea.ant-input-borderless {
        padding: 0;
        height: fit-content;
        overflow: hidden;
        resize: none;
    }
}

.ant-form-item-has-error .ooc-input__mask {
    background: @bg-secondary;
    border-color: #ff4d4f;
}


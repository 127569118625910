@import "../../theme/core.less";

.contact-us {
    margin: 40px 40px 0;
    display: flex;
    justify-content: space-between;

    .image-wrapper {
        @media screen and (min-width: 756px) {
            width: 50%;
        }
        @media screen and (max-width: 756px) {
            display: none;
        }
    }

    .ant-form-item-label {
        padding: 0 !important;

        label {
            .helptext();
            color: @text-secondary-color;
            display: block;
            padding-right: 16px;

            &:before {
                right: 0px;
                position: absolute;
            }

            @media screen and (max-width: 756px) {
                display: inline-flex !important;
                flex-direction: row-reverse;

                &:before {
                    position: initial !important;
                    padding-left: 8px;
                }
            }
        }

        margin-bottom: 4px;
    }

    .form {
        max-width: 722px;
        width: 100%;

        .form-select {
            width: 100%;

            .ant-select-selection-item {
                font-size: 16px;
                margin-top: 4px;
            }

            .ant-select-selector {
                height: 40px;
                border-radius: 4px;
            }
        }

        .user-info {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 24px;

            .ooc-input__mask[value=""],
            .ooc-input__mask[value="+7 (XXX) XXX-XX-XX"] {
                color: @text-help-color;
            }
        }
        
        .form-checkbox {
            margin-top: 10px;
            margin-left: 40px;
            .main-text();
            color: @text-primary-color;
        }

        .form-item {
            width: 100%;
            margin-bottom: 24px;
        }
    }

    .title {
        margin-bottom: 16px;
    }

    .description {
        .main-text();
        color: @text-primary-color;
        margin-bottom: 60px;
    }

    .content {
        display: flex;
        flex-direction: column;

        .join-button {
            display: flex;
            align-items: center;

            svg {
                margin-left: 8px;
            }
        }

        .col {
            max-width: 50%;
            flex: 0 0 50%;

            .col-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                font-family: @font-headers;
                color: @text-primary-color;
                margin-bottom: 8px;
                position: relative;
                z-index: 2;

                &:after {
                    z-index: -1;
                    position: absolute;
                    content: attr(data-count);
                    left: 0;
                    color: #eef3ff;
                    font-weight: bold;
                    font-size: 48px;
                    line-height: 40px;
                    top: -20px;
                    font-family: @font-headers;
                }
            }

            .col-description {
                .main-text();
                margin-bottom: 24px;
            }
        }
    }

    @media screen and (max-width: 1366px) {
        margin: initial;

        .form .form-checkbox {
            margin-left: 0px !important;
            margin-top: 10px !important;
        }
    }
}

:is(.ooc-message--success, .ooc-message--fail) {
    & > .ant-message {
        &-notice-content {
            padding: 16px 24px;
            border-radius: 4px;
       
            & .ant-message-custom-content {
                display: flex;
                align-items: center;
                color: #fff;
                .h5();

                & > svg {
                    margin-right: 12px;
                    fill: #fff;
                }
            }
        }
    }
}

.ooc-message--success > .ant-message-notice-content {
    background: linear-gradient(45deg, #51D54F, #15B012);
}

.ooc-message--warn > .ant-message-notice-content {
    background: linear-gradient(45deg, #FAA05A, #FD9A1B);
}

.ooc-message--fail > .ant-message-notice-content {
    background: linear-gradient(45deg, #FF5F5F, #EB5757);
}

@media screen and (max-width: 756px) {
    .contact-us {
        .description {
            margin-bottom: 32px;
        }
        
        .ooc-input__textarea {
            min-height: 88px !important;
        }

        .form {
            .user-info {
                display: block;
            }
        }

        .form-footer {
            margin-bottom: initial;

            .ant-form-item {
                margin-right: initial !important;
                margin-bottom: initial !important;
            }

            .ant-form-item-control-input-content {
                display: flex;
                flex-direction: column-reverse;
                gap: 24px;

                .ooc-button-v2 {
                    max-width: 100%;
                }
            }
        }
    }
}


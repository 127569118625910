@import "../../theme/core.less";

.error-boundary-line {
    display: flex;
    justify-content: space-between;
    background-color: white;
    margin-top: 40px;
    border-radius: 8px;

    &__info {
        padding: 32px;
    }

    // &__title {
    //     .h2();
    //     color: @text-primary-color;
    // }

    &__message {
        .h3();
        color: @text-secondary-color;
        margin-top: 24px;
    }

    // &__image {}
}


@import '../../theme/core.less';

.ooc-modal {
    max-width: 1254px !important;
    width: 100%;
    
    .ant-modal { 
        &-content {
            padding: 32px;
        }

        &-header {
            padding: 0 0 16px;
            border-color: @border-color;
        }

        &-title {
            .h3();
            color: @text-primary-color;
            // margin-bottom: 0px;
        }

        &-body {
            // padding: 0;
            padding: 24px 0;
        }

        &-footer {
            // margin-top: 24px;
            // padding: 24px 0 0;
            padding: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            border: 0;
        }
    }
}

// prevent body width change
// from opened ant modal
body.ant-scrolling-effect {
    width: 100% !important;
}


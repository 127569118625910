@import "../../theme/core.less";

.year-targets {
    padding-top: 70px;
    padding-bottom: 70px;

    & .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    & .list {
        display: flex;
        flex-direction: column;
        grid-column: span 3;

        & .year-target-item {
            display: flex;

            img {
                margin-left: 32px;
            }

            &:not(:last-child) {
                margin-bottom: 100px;
            }

            .item-header {
                margin-bottom: 10px;
                position: relative;
                display: flex;
                align-items: center;

                a, 
                button.ooc-button-v2 {
                    margin-left: 32px;
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 10px;
                        fill: @primary-color;
                    }
                }

                &::before {
                    content: attr(data-count);
                    position: absolute;
                    top: -25px;
                    left: 0;
                    z-index: 0;
                    .h1();
                    font-size: 48px;
                    color: #E8EFFF;
                }

                .title {
                    .h3();
                    position: relative;
                    z-index: 1;
                    color: @text-primary-color;
                    margin: 0;
                }
            }

            .item-content {
                margin-bottom: 20px;
            }

            .item-footer {
                color: @brand-second;
                font-weight: 500;

                .count {
                    .h1();
                    margin-right: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 1366px) {
    .year-targets {
        padding-top: 32px;
        padding-bottom: 32px;

        & .grid {
            grid-template-columns: 1fr !important;

            .title {
                .h2();
                margin-bottom: 50px;
            }

        }

        & .list {
            grid-column: span 1;
        }
    }
}

@media screen and (max-width: 756px) {
    .year-targets {
        padding: 20px;

        & .grid {
            grid-template-columns: 1fr;

            .title {
                margin-bottom: 40px;
                .mobile-h3();
            }
        }

        .list {
            .year-target-item {
                .item-header {
                    &::before {
                        font-size: 32px;
                        line-height: 32px;
                        top: -16px;
                    }

                    .title {
                        .mobile-h4();
                    }
                }

                .item-content {
                    .mobile-main-text();
                }

                img {
                    display: none;
                }
    
                a {
                    justify-content: flex-start;
                    display: flex;
                    align-items: center;
                    margin-top: 16px
                }

                .item-footer {
                    flex-direction: column;
                    display: flex;
                    
                    .label {
                        .buttons();
                        max-width: 80%;
                    }
                }

                .ooc-button-v2.ant-btn-link {
                    margin-top: 16px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
            }
        }
    }
}


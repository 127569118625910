@import "../../theme/core.less";

.news-block {
    .news-block__title {
        .h3();
    }

    .row {
        align-items: center;
        display: flex;
    }

    .all-news-btn {
        display: flex;
        align-items: center;
        margin-left: 32px;

        svg {
            margin-left: 12px;
        }
    }

    .title {
        margin-bottom: 0px;
    }

    .news-list {
        margin-top: 24px;
        margin-bottom: 24px;
        display: grid;
        column-gap: 100px;
        grid-template-columns: repeat(2, 1fr);

        .list-item {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 40px;
            }

            .item-image {
                object-fit: cover;
                width: 198px;
                min-width: 198px;
                border-radius: 8px;
                margin-right: 32px;
                height: 198px;
                cursor: pointer;
            }

            .item-content {
                display: flex;
                flex-direction: column;
                max-width: 854px;

                .content-header {
                    display: flex;
                    margin-bottom: 8px;

                    .header-date {
                        .helptext ();
                        color: @text-help-color;
                        display: block;
                        margin-right: 12px;
                    }

                    .header-tags {
                        .helptext ();
                        color: @text-secondary-color;
                    }
                }

                .content-body {
                    .title {
                        .h4();
                        margin-bottom: 16px;
                        cursor: pointer;
                    }

                    .description {
                        .main-text();
                        margin-bottom: 16px;
                        color: @text-primary-color;
                    }

                    .accent {
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .ant-pagination {
        text-align: right;
    }
}

@media screen and (max-width: 1366px) {
    .news-block {
        .title,
        .description {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .title {
            -webkit-line-clamp: 3;
        }

        .description {
            -webkit-line-clamp: 3;
        }

        .news-list {
            grid-template-columns: 1fr;
        }
    }
}

@media screen and (max-width: 756px) {
    .news-block {
        .news-block__title {
            .mobile-h3();
        }

        .news-list {
            grid-template-columns: 1fr;
            column-gap: 32px;
    
            .list-item { 
                border-bottom: 1px solid @el-lines-color;

                .item-image {
                    display: none;
                }
             
                .item-content .content-body .title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 8px;
                }

                .item-content .content-body .description {
                    margin-bottom: 24px;
                }

                &:not(:last-child) {
                    margin-bottom: 24px;
                }
            }
        }
    }
}


@import '../../theme/core.less';

.page-wrapper {
    height: 100%;
    background: @bg-primary;
    // negate HashLink header scroll
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    &__container {
        height: calc(100% - @nav-height);
        display: flex;
        position: relative;
    }

    &__content {
        width: 100%;
        height: calc(100% - 48px);
        overflow: auto;
        position: relative;
        transition: all 0.3s, outline 0s;

        &::-webkit-scrollbar {
            width: 8px;

            &-thumb {
                border-left: 4px solid @scroll-color;
                border-radius: 4px;

                &:hover {
                    border-left: 6px solid @scroll-color;
                }
            }
        }
        
        @media screen and (max-width: 756px) {
            padding: 16px;
        }
    }

    .page-content-wrapper {
        padding: 0 40px;
    }

    .mobile-nav-drawer {
        position: absolute;

        .ant-drawer-body {
            padding: 0;
        }

         transition: all 0.3s, outline 0s;

        &.small {
            min-width: 60px !important;
            width: 60px !important;
            transition: all 0.3s, outline 0s;
        }

        &.ant-drawer-open .ant-drawer-mask { 
            background: rgba(228, 231, 242, 0.4);
            backdrop-filter: blur(10px);
        }
    }
}

@media screen and (max-width: 1366px) {
    .page-wrapper {
        .page-content-wrapper {
            padding: 0 24px;
        }
    }
}

@media screen and (max-width: 756px) {
    .page-wrapper {
        &__content {
            & > div {
                padding-left: 0px;
                transition: all 0.3s, outline 0s;
            }
        }

        .page-content-wrapper {
            overflow-x: unset;
            padding: 0;
    
            .wrapper-content {
                min-width: auto;
                padding-top: 24px; /* works with page-mobile-corner */
            }
        }   
    }
}


@import "../../theme/core.less";

.working-industry-groups {
    .title {
        margin-bottom: 24px;
    }

    .subtitle {
        .h5();
        color: @text-primary-color;
        margin-bottom: 24px;
    }

    .divider {
        height: 1px;
        background-color: @border-color;
        width: 100%;
        margin-top: 32px;
        margin-bottom: 32px;
    }

    button {
        display: flex;
        align-items: center;
        color: white;

        svg {
            margin-right: 12px;

            path {
                fill: white;
            }
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .group-list {
        display: flex;
        flex-direction: column;

        .industry-item {
            color: @text-primary-color;
            display: flex;

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            &__count {
                .buttons();
                color: #7da9ff;
                margin-right: 8px;
            }

            &__label {
                .main-text();
            }
        }
    }
}

@media screen and (max-width: 756px) {
    .working-industry-groups {
        .grid { 
            grid-template-columns: 1fr;
            gap: 24px;

            .description {
                .mobile-main-text();
                display: inline-block;
                max-width: 92%;
            }

            .divider {
                margin: initial;
            }
        }

        .title {
            .mobile-h3();
        }

        // .group-list {
        //     .industry-item {
        //         &__count {
        //         }

        //         &__label {
        //         }
        //     }
        // }
    }
}


@import '../theme/core.less';

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  .main-text();
  color: @text-primary-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#root {
  height: 100%;
  // font-family: @font-family;
}

// body > jdiv #jvlabelWrap {
  // -86px!important
  // right: -86px !important;
// }

// page block wrapper
.page-wrapper__content .wrapper {
  margin-top: 40px;
  padding: 40px 32px;
  border-radius: 8px;
  background-color: #ffffff;
}

@media screen and (max-width: 1366px) {
  .page-wrapper__content .wrapper {
    margin-top: 32px;
    padding: 24px;
  }
}

@media screen and (max-width: 756px) {
  .page-wrapper__content .wrapper {
    margin-top: 24px;
    padding: 32px 16px;
  }
}


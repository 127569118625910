@import '../../theme/core.less';

.banner-stats-cards {
    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 32px;

        .grid-item {
            padding-top: 80px;
            padding-bottom: 40px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-size: contain;

            &.first,
            &.second,
            &.third {
                background-repeat: no-repeat;
            }

            &.first {
                background-image: url("./images/first.svg");
                background-position: 138% -58px;
            }

            &.second {
                background-image: url("./images/second.svg");
                background-position: 112% -24px;
                background-size: 52%;
            }

            &.third {
                background-image: url("./images/third-hd.svg");
                background-position: 124% -44px;
            }

            .title,
            .description {
                .h5();
                color: @text-primary-color;
            }

            .subtitle {
                color: @text-primary-color;
                font-family: @font-headers;
                font-weight: 600;
                font-size: 90px;
                line-height: 110px;
            }

            a {
                margin-top: 24px;
                font-weight: 500;
                color: @primary-color;
                display: flex;
                align-items: center;

                svg {
                    margin-left: 12px;
                }
            }
        }
    }
}

@media screen and (max-width: 1366px) {
    .banner-stats-cards {
        .grid {
            .grid-item {
                padding: 20px;
                padding-top: 60px;
                justify-content: flex-end;
                
                &.first  {
                    background-position: calc(100% + 60px) -100px;
                }

                &.second {
                    background-position: calc(100% + 60px) -100px;
                }

                &.third { 
                    background-position: calc(100% + 150px) 0px;
                }

                .title { 
                    max-width: 50%;
                }

                .subtitle {
                    font-weight: 600;
                    font-size: 70px;
                    line-height: 100px;
                    white-space: nowrap;
                }
            }
        }
    }
}

@media screen and (max-width: 756px) {
    .banner-stats-cards {
        .grid {
            grid-template-columns: 1fr;

            .grid-item {
                padding: 20px;
                padding-top: 60px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                &.first {
                    background-position: calc(100% + 48px) -48px;
                }

                &.second {
                    background-position: calc(100% + 20px) -20px;
                    background-size: 50%;
                }

                &.third {
                    background-image: url("./images/third.svg");
                    background-position: calc(100% + 44px) -52px;
                }

                .title,
                .description {
                    .main-text();
                }
                
                /* все потомки с начала до Х позиции */
                &:nth-child(-n+2) .title {
                    max-width: initial;
                }
                
                &:last-child .title {
                    max-width: 50%;
                }

                .subtitle {
                    font-weight: 700;
                    font-size: 48px;
                    line-height: 48px;
                }
            }
        }

        &__link {
            .buttons();
        }   
   }
}


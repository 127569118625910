@import '../../theme/core.less';

.consortium-educational-orgs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .ooc-button-v2.ant-btn:not(.ant-btn-block) {
        max-width: 215px;
    }

    .title {
        margin-bottom: 24px;
    }

    .description {
        margin-bottom: 40px;
    }

    .stats {
        padding-top: 40px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .stat-item {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .data {
                .h1();
                color: @text-primary-color;
                margin-bottom: 8px;
            }

            .label {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}

// @media screen and (max-width: 1366px) {
// }

@media screen and (max-width: 756px) {
    .consortium-educational-orgs {
        grid-template-columns: 1fr;

        .title {
            .mobile-h3();
        }

        .description {
            .mobile-main-text();
            margin-bottom: initial;
        }

        .stats {
            padding-top: initial;
            margin-top: 24px;
            grid-template-columns: 1fr;
            gap: 32px;
            text-align: center;

            .stat-item {
                .data {
                    margin-bottom: initial;
                }

                .label {
                    .buttons();
                    font-weight: 600;
                    width: 60%;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .ooc-button-v2.ant-btn:not(.ant-btn-block) {
            max-width: 100%;
            margin-bottom: 16px;
            margin-top: 24px;
        }
    }
}


@import "../../theme/core.less";


.digest {
    & > .header {
        display: flex;
        flex-direction: column;
        gap: 24px;

        & > .columns {
            display: flex;
            gap: 60px;

            .col1, .col2 {
                flex: 1 0;
                white-space: pre-wrap;
            }
        }
    }

    & > .content {
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;
        gap: 40px;
        overflow-x: auto;
    }

    & > .footer {
        margin-top: 24px;
    }
}

.digest-item {
    flex-basis: 0;
    flex-grow: 1;
    // fallback if less than 3 items
    max-width: 570px;
    border: 1px solid lightgray;
    border-radius: 8px;
    cursor: pointer;
    position: relative;

    .badge {
        position: absolute;
        top: 16px;
        right: 12px;
        padding: 2px 14px;
        border-radius: 24px;
        background-color: #52c41a;
        color: white;
        .buttons();
    }

    & .logo > img {
        width: 100%;
        min-height: 248px;
    }

    & .content {
        padding: 24px 32px;
    }

    & .date {
        .caption();
        color: #909EBB;
    }

    & .title {
        margin-top: 8px;
        .h4();
    }
}

// Footer
.digest-footer {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;

    .progress {
        display: flex;
    }
    
    & .count {
        .h4();

        & .current {
            color: @primary-color;
        }

        & .separator,
        & .total {
            color: @border-color;
        }
    }

    .progress-bar {
        margin-left: 24px;
        width: 180px;

        .ant-progress-inner {
            height: 2px;
        }

        .ant-progress-status-success .ant-progress-bg {
            background-color: @primary-color;
        }
    }

    & .buttons {
        cursor: pointer;

        & .rotated {
            transform: rotate(180deg);
        }
    }
}

@media screen and (max-width: 1366px) {
    .digest-item {
        // fallback if less than 3 items
        max-width: 380px;

        .logo > img {
            height: 190px;
        }
    }
}

@media screen and (max-width: 756px) {
    .digest {
        .header {
            .columns {
                display: none;
            }
        }
    }

    .digest-item {
        max-width: initial;
        min-width: 318px;
    }
}


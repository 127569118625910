@import "../../theme/core.less";

.error-boundary {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: calc(100vh - 84px);

    &-text {
        margin-top: 40px;
        .h3();
        color: @text-primary-color;
    }
}


@import '../../../theme/core.less';

.main-events-item {
    min-width: 200px;

    &__banner {
        width: 100%;
        height: 100%;
    }
}


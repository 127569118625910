@import "../../theme/core.less";

.title {
    color: @text-primary-color;
    .h2();
    font-weight: bold;
}

.yt-lite > .lty-playbtn {
    border-radius: 25%;
    border-width: 0;
}

.yt-lite > .lty-playbtn:before {
    left: 36px !important;
}


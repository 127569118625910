@import "../../theme/core.less";

.our-common-task {
    padding: 71px 85px;
    background-size: contain;
    background-image: url("../../assets/images/our-common-task/photo-hd.png");
    background-position: right;
    background-repeat: no-repeat;

    .task-content {
        max-width: 867px;
        margin-top: 32px;
        margin-left: 52px;

        .message {
            .h5();
            line-height: 28px;
            margin-bottom: 50px;
            position: relative;
            z-index: 0;

            &::before,
            &::after {
                content: "";
                display: inline-block;
                height: 36px;
                width: 48px;
                position: absolute;
                z-index: -1;
            }

            &::before {
                background-image: url("../../assets/images/our-common-task/quote-left.png");
                top: -16px;
                left: -16px;
            }

            &::after {
                background-image: url("../../assets/images/our-common-task/quote-right.png");
                right: 0;
                bottom: -16px;
            }
        }

        .user {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .name {
                .h4();
                margin-bottom: 8px;
            }

            .pos {
                .helptext();
                color: @text-secondary-color;
                max-width: 60%;
                text-align: right;
            }
        }
    }
}

@media screen and (max-width: 1366px) {
    .our-common-task {
        padding: 50px 70px;
        background-size: 50%;
        background-size: 350px;
        background-position: 100% 100%;
        
        .task-content  {
            .user {
                align-items: center;
            }
            
            .message {
                max-width: 80%;
            }
        }
    }
}

@media screen and (max-width: 756px) {
    .our-common-task {
        background-image: url("../../assets/images/our-common-task/photo-mobile.png");
        background-size: 530px;
        background-position: bottom center;
        padding-bottom: 300px;
        padding-top: 50px;

        .task-content {
            margin: 24px 16px 0 24px;
            padding-bottom: 300px;

            .message {
                max-width: initial;
                .mobile-main-text();

                &::before {
                    top: -32px;
                    left: -16px;
                }

                &::after {
                    right: -20px;
                    bottom: -4px;
                }
            }

            .user {
                align-items: flex-end;
                text-align: right;

                .pos {
                    max-width: 94%;
                    white-space: pre-line;
                }
            }
        }
    }
}


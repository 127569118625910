@import "../../theme/core.less";

.page-header {
    padding: 40px;
    background: @text-secondary;
    display: flex;
    justify-content: space-between;
    position: relative;

    .page-banner {
        position: absolute;
        right: 0px;
    }

    &__title-wrapper {
        color: @text-primary-color;
    }

    &__title {
        .h2();
    }

    &__motto {
        max-width: 80%;
        .main-text();
    }

    &__action-wrapper {
        .buttons();
        color: @brand-primary-color;

        .ooc-button-v2 {
            height: fit-content;
            border: unset;
            display: flex;
            align-items: flex-start;

            &.primary { 
               height: 44px;
               display: flex;
               align-items: center;
            }

            & > span {
                margin-left: 8px;
            }
        }
    }
}

@media screen and (max-width: 1366px) {
    .page-header {
        padding: 32px;
    }
}

@media screen and (max-width: 756px) {
    .page-header {
        // чтобы хедер "приклеивался" к краям страницы
        margin: -20px -20px 0;
        padding: 24px 20px;

        &__title {
            max-width: 200px;
        }

        &__motto {
            max-width: 100%;
        }

        .page-banner {
            height: 80px;
            bottom: -44px;
        }
    }

    .about-page {
        .page-header-top {
            // чтобы хедер "приклеивался" к краям страницы
            margin: -20px -20px 0;
            padding: 24px 20px 0;
            background-color: white;

            &__title {
                .mobile-h1();
                color: @text-primary-color;
                position: relative;
                z-index: 2;
            }

            &__icon {
                text-align: right;
                margin: -20px 0 -76px;
                position: relative;
                z-index: 1;
            }
        }

        .page-header {
            &__title-wrapper {
                position: relative;
                z-index: 1;
            }

            &__title {
                max-width: initial;
                .mobile-main-text-semibold-v2();
                font-size: 24px;
            }

            &__motto {
                margin-top: 16px;
            }
        }
    }
}


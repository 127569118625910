@import "../../theme/core.less";

.news-details-drawer {
    .detail-date {
        .caption();
        color: @text-help-color;
        margin-bottom: 16px;
    }

    .ant-drawer-content-wrapper {
        max-width: 1000px;
        width: 100% !important;

        .ant-drawer-header {
            margin-left: 32px;
            margin-right: 32px;
            padding: 0;
            padding-top: 32px;
            padding-bottom: 16px;

            .ant-drawer-title {
                .h3();
            }

            .ant-drawer-close {
                top: 16px;
            }
        }

        .ant-drawer-body {
            padding-left: 108px;
            padding-right: 92px;
            .main-text();

            img {
                border-radius: 8px;
                margin-top: 16px;
                margin-bottom: 16px;
                max-width: 100%;
                height: auto;
            }

            h1 {
                .h3();
                margin: 0;
            }

            strong {
                color: rgb(33, 37, 41);
            }
        }
    }

    .last-news {
        margin-top: 24px;
        border-top: 2px solid @el-lines-color;
        padding: 24px 0;
        .last-news-title {
            .h4();
            color: @text-primary-color;
            margin-bottom: 24px;
        }

        .news-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 40px;

            .grid-item {
                cursor: pointer;
                
                .item-date {
                    .caption();
                    color: @text-help-color;
                    margin-bottom: 8px;
                }
                .item-image {
                    width: 100%;
                    height: 80px;
                    min-height: 80px;
                    object-fit: cover;
                    padding: 0;
                    margin: 0;
                    margin-bottom: 8px;
                }
                .item-title {
                    .buttons();
                    color: @text-primary-color;
                    margin-bottom: 8px;
                }
            }
        }
    }
}

@media screen and (max-width: 757px) {

    .news-details-drawer .ant-drawer-content-wrapper .ant-drawer-body {
         padding-left: 32px;
         padding-right: 32px;
    }
    
    .news-details-drawer .last-news .news-grid {
        grid-template-columns: 1fr;
    }
}

@import '../../theme/core.less';

.nav-sub-menu {
    padding: 0 40px !important;
    .h5() !important;
    color: @primary-color !important;

    & .ant-tabs-tab-btn {
        color: @text-secondary-color;
        padding: 0 20px;
    }

    & .ant-tabs-ink-bar {
        height: 2px !important;
    }
}


@import '../../theme/core.less';

.main-events {
    width: 100%;

    & .feature-title {
        margin-bottom: 32px;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 32px;
    }
}

@media screen and (max-width: 756px) {
    .main-events {
        & .feature-title {
            margin-bottom: 16px;
        }

        .slick-slider {
            .slick-arrow {
                display: none !important;
            }

            .slick-list {
                overflow-x: hidden;

                .slick-track {
                    display: flex;
                }
            }
        }
    }
}

